<template>
    <div>
        <div v-if="authUserPermission['plant-sp-view']">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>      
                </div>
            </base-header>
            <div class="container-fluid mt--6">
                <div class="card mb-4">
                    <div class="card-header mt--4 mb--4">
                        <div class="row">
                            <div class="col-7">
                                <h3 class="mb-0">{{ tt('plant_sp') }}</h3>
                            </div>
                            <div class="col-5">
                                <div class="form-group">
                                    <input type="text" class="form-control form-control-sm text-center border-radius-20" v-model="table.search" :placeholder="tt('search_plant_sp')" v-on:keyup="filter"/>                            
                                </div>
                            </div>
                           <!--  <div class="col text-right">
                                <base-button size="sm" type="default" @click="create" v-if="authUserPermission['plant-sp-create']">{{ tt('add_new') }}</base-button>
                            </div> -->
                        </div>
                    </div>
                    <div>
                        <el-table height="350px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="table.data" v-if="!onLoad">
                            <el-table-column :label="tt('code')" :prop="tt('code')" sortable>
                                <template v-slot="{row}">
                                    {{row.plant_sp_code}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('description')" :prop="tt('description')" sortable>
                                <template v-slot="{row}">
                                    {{row.plant_sp_description}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="action" width="100">
                             <!--    <template v-slot="{row}">
                                    <el-dropdown trigger="click" class="dropdown" @command="handleTableAction" :hide-on-click="true" v-if="authUserPermission['plant-sp-update'] || authUserPermission['plant-sp-delete']">
                                        <span class="btn btn-sm btn-icon-only text-light">
                                            <i class="fas fa-ellipsis-v mt-2"></i>
                                        </span>
                                        <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                            <el-dropdown-item :command="{action:'edit', data:row.id}" v-if="authUserPermission['plant-sp-update']">{{ tt('edit') }}</el-dropdown-item>
                                            <el-dropdown-item :command="{action:'remove', data:row.id}" v-if="authUserPermission['plant-sp-delete']">{{ tt('delete') }}</el-dropdown-item>                                        
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </template> -->
                            </el-table-column>
                        </el-table>
                        <page-loading v-else/>
                    </div>            
                    <div class="card-footer pb-0 ">   
                        <span class="float-left">
                            <base-pagination :page-count="table.page.last_page" v-model="table.page.current_page" @click.native="changePage(table.page.current_page)" size="sm"></base-pagination>
                        </span>                             
                        <span class="float-right">
                            {{ tt('page_info', {size: (table.page.current_page - 1) * table.page.per_page + table.data.length, total: table.total}) }}
                        </span>
                    </div>
                </div>
            </div>
            <validation-observer v-slot={invalid}>
                <modal :show.sync="form.show">
                    <template slot="header">
                        <h5 class="modal-title">{{form.title}}</h5>
                    </template>
                    <div>
                        <base-alert type="danger" v-if="errorMessage">
                            <p><strong>{{ errorMessage.message }}</strong></p>
                            <div v-if="errorMessage.data.length != 0">
                                <span v-for="message in errorMessage.data">
                                    {{ message[0] }}<br>
                                </span>
                            </div>
                        </base-alert>
                        <label class="form-control-label">{{ tt('code') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('code')" :placeholder="tt('code')" v-model="plantSp.plant_sp_code" rules="required"></base-input>

                        <label class="form-control-label">{{ tt('description') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('description')" :placeholder="tt('description')" v-model="plantSp.plant_sp_description" rules="required"></base-input>
                    </div>
                    <template slot="footer">
                        <base-button type="secondary" @click="form.show = false">{{ tt('close') }}</base-button>
                        <base-button type="primary" v-on:click="save" :disabled="btnSave.onLoading || invalid">
                            <span v-if="btnSave.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                            <span v-else>
                                <span v-if="form.add">{{ tt('add') }}</span>
                                <span v-else>{{ tt('edit') }}</span>
                            </span>
                        </base-button>
                    </template>
                </modal>
            </validation-observer>
        </div>
        <noaccess v-else/>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import plantSp from '@/services/master/plantSp.service';

    export default {        
        data() {
            return {        
                onLoad: true,      
                loadTimeout: null,  
                btnSave: {
                    onLoading: false
                },             
                form: {
                    add: true,
                    title: this.tt('add_plant_sp'),
                    show: false
                },   
                table: {
                    search: '',                    
                    total: 0,
                    data: [],
                    page: {
                        current_page: 1,
                        per_page: 100,
                    },
                },     
                errorMessage: null,    
                plantSp: {
                    
                }  
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted() {
            this.get()
        },
        methods: {
            get() { 
                let context = this;               
                Api(context, plantSp.get(this.table.page.current_page, {search: this.table.search})).onSuccess(function(response) {
                    context.table.total = response.data.data.data.total;
                    context.table.data = response.data.data.data.data; 
                    context.table.page  = response.data.data.data;  
                    context.onLoad = false;          
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.table.data = [];
                        context.table.total = 0;
                        context.onLoad = false;
                    }
                })
                .call()
            },
            filter() {
                this.onLoad = true;
                this.table.page.current_page = 1;
                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    console.log('asd');
                    this.get()
                }, 100);
            },
            handleTableAction(command) {
                switch (command.action) {
                    case 'edit':
                            this.edit(command.data);
                        break;
                    case 'remove':
                            this.remove(command.data);
                        break;
                    default:
                        break;
                }
            },
            create() {
                this.errorMessage = null;
                this.form.add = true;
                this.form.title = this.tt('add_plant_sp');
                this.form.show = true;
                this.plantSp = {};
            },            
            edit(id) {
                this.errorMessage = null;
                let context = this;               
                Api(context, plantSp.show(id)).onSuccess(function(response) {    
                    context.plantSp = response.data.data;
                    context.form.add = false;
                    context.form.title = context.tt('edit_plant_sp');
                    context.form.show = true;                          
                })
                .call()        
            },
            save() {                      
                this.btnSave.onLoading = true;
                let api = null;
                let context = this;
                if (this.form.add) {
                    api = Api(context, plantSp.create(this.plantSp));
                } else {{
                    api = Api(context, plantSp.update(this.plantSp.id, this.plantSp));
                }}
                api.onSuccess(function(response) {
                    context.get();
                    context.$notify({
                        message: response.data.message,                  
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                    context.form.show = false;
                }).onError(function(error) {                    
                    context.errorMessage = error.response.data;
                }).onFinish(function() {
                    context.btnSave.onLoading = false;   
                })
                .call();
            },
            remove(id) {
                this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    let context = this;
                    if (result.value) {
                        Api(context, plantSp.delete(id)).onSuccess(function(response) {
                            context.$notify({
                                message: response.data.message,
                                type: 'success',
                                verticalAlign: 'bottom', 
                                horizontalAlign: 'left'
                            });
                            context.get();
                        }).call();
                    }
                })
            },
            changePage(page) {
                let context = this;
                context.onLoad = true;

                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.get()
                }, 100);
            },
        }   
    };
</script>
<style></style>
